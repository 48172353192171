import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import CheckBox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
    ButtonGroup,
    DialogContent,
    DialogContentText,
    Grid,
} from '@mui/material';
import {useState, useEffect} from 'react';
import {axiosNpsWorkersInstance} from "../Utilities/axios";
import {useEmployeeContext} from "../context/PuzzelContext";

const ratings = Array.from(Array(11).keys()).map(String);

function getTemplate(queueKey): Promise<any> {
    return axiosNpsWorkersInstance.get(`puzzel/template?queueKey=${queueKey}`);
}

const validateQuestionsArray = (arr) => {
    //filter arr to strings that can be parsed to json
    arr = arr.filter((e) => {
        try {
            e = JSON.parse(e.value);
            //if no agent question or agent question is false
            if (e.agentQuestion === null || !e.agentQuestion) return false;
            return true;
        } catch (e: any) {
            return false;
        }
    });
    //parse strings in array to json
    arr = arr.map((elem) => {
        elem.value = JSON.parse(elem.value);
        return elem;
    });

    return arr;
};


export default function PGapDialog() {
    const {puzzelState, campaignMemberModel, shouldHavePGap, setShouldHavePGap, setPuzzelState, setCampaignMemberModel} = useEmployeeContext();
    const {queueKey} = puzzelState;

    const handleListItemClick = (value: string, answers?: string) => {
        //go to page 2 if rating was picked and there are agent questions
        if (page === 1 && questions.length > 0) {
            setRating(value);
            setPage(2);
        }
        //in any other scenario, close the dialog and clean up states
        else {
            setShouldHavePGap(false)
            const valueNumber = Number(value)
            setPuzzelState({
                ...puzzelState,
                agentRating: valueNumber,
                agentAnswers: answers
            })
            setCampaignMemberModel({
                ...campaignMemberModel,
                agentRating: valueNumber,
                agentAnswers: answers
            })


            setRating('none');
            setPage(1);
            setQuestions([]);
            setAnswers([]);
            setTemplateFetched(false);
        }
    };
    const [rating, setRating] = useState('none');
    const [page, setPage] = useState(1); //page 1 is rating, page 2 is further questions.
    const [questions, setQuestions] = useState<any[]>([]);
    const [answers, setAnswers] = useState<any[]>([]);
    const [templateFetched, setTemplateFetched] = useState(false);

    const handleChange = (event) => {
        const type = event.target.type;
        const name = event.target.name;
        const questionNum = parseInt(name.split('answer')[1]);
        const value = event.target.value;
        let answersCopy = answers;

        if (type === 'checkbox') {
            const checked = event.target.checked;
            answersCopy.forEach((element) => {
                if (checked && element.questionNum === questionNum)
                    element.answer.push(value);
                if (!checked && element.questionNum === questionNum)
                    element.answer.splice(element.answer.indexOf(value), 1);
            });
        }
        if (type === 'radio') {
            answersCopy.forEach((element) => {
                if (element.questionNum === questionNum) element.answer = [value];
            });
        }
        setAnswers(answersCopy);
    };
    useEffect(() => {
			if (shouldHavePGap) {
				getTemplate(queueKey).then((template) => {
					const questionKeys = ['question1','question2','question3','question4','question5'];

					// Filter out null questions and map them to the required structure
					const questions = questionKeys
						.map((key, index) => {
							return template[key] !== null
								? { value: template[key], questionNum: index + 1 }
								: null;
						})
						.filter((q) => q !== null);

					// Get Validated Questions (questions with agentQuestion = true)
					const validatedQuestions = validateQuestionsArray(questions);
					setQuestions(validatedQuestions);

					// Create answers structure from the questions
					const answers = validatedQuestions.map((q) => ({
						questionNum: q.questionNum,
						answer: [],
						question: q.value.question,
					}));
					setAnswers(answers);
					setTemplateFetched(true);
				});
			}
		}, [queueKey, shouldHavePGap]);
    

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleListItemClick(rating, JSON.stringify(answers));
    };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={shouldHavePGap && templateFetched}
            maxWidth={'xl'}
        >
            {page === 1 && (
                <>
                    <DialogTitle id="simple-dialog-title">P-gap</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Based on your recent interaction on this case, how satisfied do
                            you think the customer is?
                        </DialogContentText>
                    </DialogContent>
                    <ButtonGroup
                        variant="contained"
                        color="secondary"
                        aria-label="outlined secondary button group"
                        size="large"
                        fullWidth={true}
                    >
                        {ratings.map((rating, key) => (
                            <Button key={key} onClick={() => handleListItemClick(rating)}>
                                {rating}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="h6"
                            // className={classes.padding}
                        >
                            Not satisfied
                        </Typography>
                        <Typography
                            variant="h6"
                            // className={classes.padding}
                        >
                            Very satisfied
                        </Typography>
                    </Grid>
                </>
            )}

            {page === 2 && (
                <>
                    <DialogTitle id="simple-dialog-title">
                        P-gap{page === 2 && ' (agent questions)'}
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                                {questions.length > 0 ? (
                                    questions.map((q, key) => {
                                        const value = q.value;
                                        const questionNum = q.questionNum;
                                        return (
                                            <div key={key}>
                                                <FormLabel component="legend">
                                                    {value.question}
                                                </FormLabel>
                                                {value.allowMultipleAnswers ? (
                                                    <FormGroup row>
                                                        {value.choices.map((c, key) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={key}
                                                                    onChange={handleChange}
                                                                    name={'answer' + questionNum}
                                                                    value={c}
                                                                    control={<CheckBox/>}
                                                                    label={c}
                                                                />
                                                            );
                                                        })}
                                                    </FormGroup>
                                                ) : (
                                                    <RadioGroup row>
                                                        {value.choices.map((c, key) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={key}
                                                                    onChange={handleChange}
                                                                    name={'answer' + questionNum}
                                                                    value={c}
                                                                    control={<Radio required={true}/>}
                                                                    label={c}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                )}
                                                <br/>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p>fetching questions...</p>
                                )}
                                <DialogActions disableSpacing={true}>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        style={{color: 'rgb(136, 188, 165)'}}
                                    >
                                        finish
                                    </Button>
                                </DialogActions>
                            </FormControl>
                        </form>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
}
