import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/WorkSans/WorkSans-SemiBold.ttf';
import './fonts/WorkSans/WorkSans-Regular.ttf';
import {ThemeProvider} from '@mui/material';
import {theme} from './Utilities/theme';
import {PuzzelProvider} from "./context/PuzzelContext";
import { SnackbarProvider } from 'notistack'


require('dotenv').config();

ReactDOM.render(
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} preventDuplicate>
                <PuzzelProvider>
                    <App/>
                </PuzzelProvider>
            </SnackbarProvider>
        </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
