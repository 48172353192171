import React, {useEffect} from 'react';
import {useLocalStorageState, useRequest} from 'ahooks';
import axios from '../Utilities/axios';
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import ScoreBarChart from './ScoreBarChart';
import ScoreBlock from './ScoreBlock';
import NPSGauge from './NPSGauge';
import {useEmployeeContext} from "../context/PuzzelContext";

export interface AgentAnalytics {
    ratingsCount: { [key: string]: number };
    count: number;
    averageRating: number;
    fromDate: Date;
}

export default function AgentOverview() {
    const {employeeState} = useEmployeeContext();
    const {email} = employeeState;
    const [days, setDays] = useLocalStorageState<number>('setDays-state', {
        defaultValue: 30,
    });
    const getAgentOverview = (): Promise<AgentAnalytics> => {
        return axios.get(`/employees/analytics?email=${email}&days=${days}`)
    };

    const {data, loading, run} = useRequest(getAgentOverview
        , {
            cacheKey: `AgentOverview-${days}`,
            pollingInterval: 5 * 60 * 1000, // 5 minutes
            manual: true,
            staleTime: 60 * 1000, // 1 minute
        });

    useEffect(() => {
        if (!email || !days) return;
        run();
    }, [days, email, run]);

    if (!data) return <></>;

    return (
        <Grid
            container
            item
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <BasicSelect value={days ?? 30} setValue={setDays}/>
            </Grid>
            <Grid
                container
                item
                direction="row"
                justifyContent="space-around"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12} sm={6}>
                    <ScoreBlock
                        isLoading={loading}
                        bigText={data.count}
                        explainerText="Responses"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ScoreBlock
                        isLoading={loading}
                        bigText={data.averageRating}
                        explainerText="Avg. NPS Rating"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NPSGauge nps={data} isLoading={loading}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ScoreBarChart agentAnalytics={data} isLoading={loading}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

function BasicSelect({
                         value,
                         setValue,
                     }: {
    value: number;
    setValue: (age: number) => void;
}) {
    const handleChange = (event: SelectChangeEvent) => {
        setValue(Number(event.target.value));
    };

    return (
        <Box sx={{maxWidth: 200}}>
            <FormControl fullWidth>
                <InputLabel id="select-label">Data based on</InputLabel>
                <Select
                    labelId="select-label"
                    value={value.toString()}
                    label="Data based on"
                    onChange={handleChange}
                    size={'small'}
                >
                    <MenuItem value={7}>Last week</MenuItem>
                    <MenuItem value={30}>Last month</MenuItem>
                    <MenuItem value={90}>Last 3 month</MenuItem>
                    <MenuItem value={365}>Last year</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
