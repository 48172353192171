import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import moment from 'moment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import CallIcon from '@mui/icons-material/Call';
import Divider from '@mui/material/Divider';
import {ReactComponent as AgentIcon} from '../Icons/support_agent_black_24dp.svg';
import RatingAvatar from './RatingAvatar';
import {Response} from '../models/nps';

function tryParseJson(json: any) {
    try {
        const parsed = JSON.parse(json);
        const type = typeof parsed;
        //only allow objects.
        if (type === 'object') return parsed;
        return null;
    } catch {
        return null;
    }
}

//generates caller and agent lists on the datagrid that represent the multiple choice result
function getAnswers(props: any) {
    let callerAnswers = [
        props.answer1,
        props.answer2,
        props.answer3,
        props.answer4,
        props.answer5,
    ].map((a) => tryParseJson(a));
    const agentAnswers = [null, null, null, null, null] as any;
    const questions = [
        callerAnswers[0] && callerAnswers[0].question
            ? callerAnswers[0].question
            : 'question 1',
        callerAnswers[1] && callerAnswers[1].question
            ? callerAnswers[1].question
            : 'question 2',
        callerAnswers[2] && callerAnswers[2].question
            ? callerAnswers[2].question
            : 'question 3',
        callerAnswers[3] && callerAnswers[3].question
            ? callerAnswers[3].question
            : 'question 4',
        callerAnswers[4] && callerAnswers[4].question
            ? callerAnswers[4].question
            : 'question 5',
    ] as any;
    //get full AgentAnswer column json
    if (props.agentAnswers) {
        const agentAnswersAndQuestions = JSON.parse(props.agentAnswers);
        //map to agentAnswers array
        agentAnswersAndQuestions.forEach((element) => {
            for (var i = 0; i < 5; i++) {
                if (element.questionNum === i + 1 && element.answer.length > 0) {
                    agentAnswers[i] = element.answer;
                    if (element.question) questions[i] = element.question;
                }
            }
        });
        //map caller answer to null if agent has not answered same question
        callerAnswers = callerAnswers.map((c, key) => {
            return agentAnswers[key] === null ? null : c;
        });
    }
    //if caller has no answers - return.
    if (callerAnswers.every((val: any) => val === null || val === undefined))
        return;
    //do not generate the lists if no answer was found between caller and agent
    return (
        <>
            {/*list containing caller answers on multiple choice*/}
            <List style={{paddingTop: '0px', paddingBottom: '0px'}}>
                {callerAnswers &&
                    callerAnswers.map((value, key) => {
                        return (
                            value && (
                                <ListItem
                                    style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        display: 'block',
                                    }}
                                >
                                    <ListItemText
                                        primary={handlePrimaryTitle(value.answer)}
                                        secondary={handleSecondaryTitle(questions[key])}
                                    />
                                    <ListItemSecondaryAction>
                                        <CallIcon/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        );
                    })}
            </List>
            {props.showPgapRating &&
                props.agentAnswers &&
                props.agentAnswers.length > 0 && (
                    <Divider orientation="vertical" flexItem/>
                )}
            {/*list containing agent answers on multiple choice*/}
            <List style={{paddingTop: '0px', paddingBottom: '0px'}}>
                {props.showPgapRating &&
                    agentAnswers &&
                    agentAnswers.map((value, key) => {
                        return (
                            value &&
                            callerAnswers[key] && (
                                <ListItem
                                    style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        display: 'block',
                                    }}
                                >
                                    <ListItemText
                                        primary={handlePrimaryTitle(value)}
                                        secondary={handleSecondaryTitle(questions[key])}
                                    />
                                    <ListItemSecondaryAction>
                                        <AgentIcon
                                            fill={getAgentIconColor(agentAnswers, callerAnswers, key)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        );
                    })}
            </List>
        </>
    );
}

function getAgentIconColor(
    agentAnswers: any[],
    callerAnswers: any[],
    key: number
) {
    //agent guessed all caller answers correct
    const isSubset = agentAnswers[key].every(
        (val: any) =>
            callerAnswers[key].answer && callerAnswers[key].answer.includes(val)
    );
    if (isSubset) return '#88BCA5'; //green

    //agent guessed some caller answers correct
    const containsAnswers = agentAnswers[key].some(
        (val: any) =>
            callerAnswers[key].answer && callerAnswers[key].answer.includes(val)
    );
    if (containsAnswers) return '#F5D45F'; //yellow

    //agent guessed none of the caller answers correct
    return '#C64D4A'; //red
}

//used for shortening a title. The tooltip will show the full title.
function getTitleWithTooltip(maxLength: number, title: any) {
    return (
        <Tooltip title={title} placement="top-start">
            <span>{title.substring(0, maxLength + 1) + '...'}</span>
        </Tooltip>
    );
}

function handlePrimaryTitle(answer: any) {
    if (!answer) return;
    let answerTooLong = false;
    const maxLength = 26;
    answer.sort(); //sort alphabetically
    const seperateByComma = answer.map((v: string, index: number) => {
        return index < answer.length - 1 ? v + ', ' : v;
    });
    if (seperateByComma.length > maxLength) answerTooLong = true;
    return answerTooLong
        ? getTitleWithTooltip(maxLength, seperateByComma)
        : seperateByComma;
}

//secondary title is the question that was asked for an agent or caller
function handleSecondaryTitle(question: any) {
    const maxLength = 30;
    const questionTooLong = question.length > maxLength;
    if (!questionTooLong) return question;
    return getTitleWithTooltip(maxLength, question);
}

interface ResponseProps {
    response: Response;
    showPgapRating: boolean;
    showRespondentPhone: boolean;
}

// TODO chat icon - add campaign name to response

function ResponseDetails({
                             response,
                             showPgapRating,
                             showRespondentPhone,
                         }: ResponseProps) {
    const pGap =
        response.agentRating || response.agentRating === 0
            ? Math.abs(response.rating - response.agentRating)
            : 0;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={response.respondentComment && <ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        item
                        xs
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <div style={{textAlign: 'center'}} className="container">
                            <Tooltip title="Caller">
                                <RatingAvatar rating={response.rating}/>
                            </Tooltip>
                            {showPgapRating &&
                            (pGap === 0 || pGap) &&
                            (response.agentRating || response.agentRating === 0) ? (
                                <div>
                                    <div className="bar">
                                        <div className="center">p-gap</div>
                                        <div className="line"/>
                                        <div className="center">{pGap}</div>
                                    </div>
                                </div>
                            ) : null}
                            {showPgapRating &&
                                (response.agentRating || response.agentRating === 0) && (
                                    <span className="MuiTypography-body1">
										<Tooltip title="Agent">
											<RatingAvatar rating={response.agentRating} pGap={pGap}/>
										</Tooltip>
									</span>
                                )}
                            {getAnswers({...response, showPgapRating: true})}
                            <Typography
                                // className={classes.secondaryHeading}
                            >
                                {`
							${moment(response.time).fromNow()}
							`}
                                <Box px={7}/>
                            </Typography>
                            {response.category && (
                                <Chip size="small" label={response.category} color="primary"/>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </AccordionSummary>
            {showRespondentPhone && (
                <AccordionDetails>
                    <Typography>{response.respondentPhoneNumber}</Typography>
                </AccordionDetails>
            )}
            {response.respondentComment && (
                <AccordionDetails>
                    <Typography>{response.respondentComment}</Typography>
                </AccordionDetails>
            )}
        </Accordion>
    );
}

export default ResponseDetails;
