import axios from 'axios';

// ----------------------------------------------------------------------

export const axiosNpsInstance = axios.create({
    baseURL: process.env.REACT_APP_NPS_ENVIRONMENT_BASEURL ?? 'https://localhost:5001' //'https://api.nps.today';,
});

axiosNpsInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosNpsInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        console.log(error);
        return Promise.reject(
            (error.response && error.response.data) ||
            error.message ||
            'Something went wrong'
        );
    }
);

export const axiosNpsWorkersInstance = axios.create({
    baseURL: process.env.REACT_APP_NPS_ENVIRONMENT_WORKERS_BASEURL ?? 'http://localhost:7071/api'
});

axiosNpsWorkersInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosNpsWorkersInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        console.log(error);
        return Promise.reject(
            (error.response && error.response.data) ||
            error.message ||
            'Something went wrong'
        );
    }
);

export const axiosPuzzelInstance = axios.create({
    baseURL: 'https://api.puzzel.com/ContactCentre5'
});

export default axiosNpsInstance;
