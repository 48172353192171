import {
    Box,
    Card,
    Grid,
    LinearProgress,
    LinearProgressProps,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {styled} from '@mui/system';
import {AgentAnalytics} from "./AgentOverview";

const sumPercentage = (arr: number[]) => {
    let count = 0;
    if (arr.length > 0) {
        arr.forEach((element) => {
            if (element) count += element;
        });
    }
    return count;
};

export type ScoreBarChartProps = {
    agentAnalytics: AgentAnalytics;
    isLoading: boolean;
}

export default function ScoreBarChart({agentAnalytics, isLoading}: ScoreBarChartProps) {
    const theme = useTheme();
    const CONTENTS = [
        {
            type: 'Heavy detractor',
            description: '0-2',
            amount: sumPercentage([
                agentAnalytics.ratingsCount?.zero,
                agentAnalytics.ratingsCount?.one,
                agentAnalytics.ratingsCount?.two,
            ]),
            color: theme.palette.error.dark,
        },
        {
            type: 'Medium detractor',
            description: '3-4',
            amount: sumPercentage([agentAnalytics.ratingsCount?.three, agentAnalytics.ratingsCount?.four]),
            color: theme.palette.error.main,
        },
        {
            type: 'Light detractor',
            description: '5-6',
            amount: sumPercentage([agentAnalytics.ratingsCount?.five, agentAnalytics.ratingsCount?.six]),
            color: theme.palette.error.light,
        },
        {
            type: 'Passive',
            description: '7-8',
            amount: sumPercentage([agentAnalytics.ratingsCount?.seven, agentAnalytics.ratingsCount?.eight]),
            color: theme.palette.warning.main,
        },
        {
            type: 'Promoter',
            description: '9-10',
            amount: sumPercentage([agentAnalytics.ratingsCount?.nine, agentAnalytics.ratingsCount?.ten]),
            color: theme.palette.success.main,
        },
    ];

    return (
        <StyledCard>
            <Stack
                justifyContent="center"
                style={{height: '100%', width: '100%'}}
                direction={{xs: 'column'}}
            >
                {isLoading && <LinearProgress/>}
                <Stack spacing={1.5} sx={{px: 2, my: 2}}>
                    {CONTENTS.map((content, index) => {
                        return (
                            <LinearProgressWithLabel
                                variant="determinate"
                                key={index}
                                value={agentAnalytics.count > 0 ? (content.amount / agentAnalytics.count) * 100 : 0}
                                sx={{
                                    height: 8,
                                    bgcolor: '#ebebeb',
                                    '>*': {
                                        backgroundColor: `${content.color} !important`,
                                    },
                                }}
                            />
                        );
                    })}
                </Stack>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{px: 3, pb: 3}}
                >
                    {CONTENTS.map((content, index) => {
                        return (
                            <Grid key={index} item xs>
                                <Stack alignItems="center">
                                    <Tooltip title={content.description}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{mb: 1}}
                                        >
                                            <Box
                                                sx={{
                                                    minWidth: 12,
                                                    minHeight: 12,
                                                    borderRadius: 0.5,
                                                    bgcolor: content.color,
                                                }}
                                            />
                                            <Typography variant="body1">{content.amount}</Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{color: 'text.secondary'}}
                                            >
                                                {content.type}
                                            </Typography>
                                        </Stack>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                        );
                    })}
                </Grid>
            </Stack>
        </StyledCard>
    );
}

const StyledCard = styled(Card)(() => ({
    height: '100%',
    display: 'flex',
}));

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
