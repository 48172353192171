import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory, BrowserHistoryOptions } from 'history';

export const reactPlugin = new ReactPlugin();

export const setupAppInsights = () => {
  const connectionString = 'InstrumentationKey=4bc4b968-180f-4730-b127-844ab8de8570;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/;ApplicationId=baeab1a0-fa24-4ceb-8ffa-f793e7f66c2e';

  if (!connectionString) {
    console.error('Application Insights connection string is missing.');
    return;
  }

    const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions);

  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      disableCorrelationHeaders: true, // Disable correlation headers including traceparent
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });

  appInsights.loadAppInsights();
  console.log('Application Insights initialized.');
};