import React, {useState} from 'react';
import {Stack} from '@mui/material';
import AgentResponses from './AgentResponses';
import PhoneResponses from './PhoneResponses';
import NavigationTabs from './NavigationTabs';
import AgentOverview from './AgentOverview';

enum Page {
    Agent,
    Phone,
}

function Ratings() {
    const [page, setPage] = useState(0);

    return (
        <Stack pt={3} pl={2} pr={2} spacing={3}>
            <NavigationTabs value={page} setValue={setPage} />
            {page === Page.Agent && (
                <Stack>
                    <AgentOverview/>
                    <AgentResponses/>
                </Stack>
            )}
            {page === Page.Phone && <PhoneResponses/>}
        </Stack>
    );
}

export default Ratings;
