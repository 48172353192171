import { CircularProgress, Grid, Paper, Typography } from '@mui/material';

export type ScoreBlockProps = {
	bigText: string | number;
	explainerText: string;
	isLoading: boolean;
};

function ScoreBlock({ bigText, explainerText, isLoading }: ScoreBlockProps) {
	return (
		<Paper sx={{ py: 1 }}>
			<Grid
				spacing={3}
				direction="column"
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid item xs>
					<Typography sx={{ textAlign: 'center' }} variant="h2" component="h2">
						{isLoading ? <CircularProgress /> : bigText ? bigText : 0}
					</Typography>
				</Grid>

				<Grid item xs>
					<Typography
						variant="subtitle2"
						sx={{ textAlign: 'center' }}
						color="textSecondary"
					>
						{explainerText}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default ScoreBlock;
