import LatestResponses from './LatestResponses';
import React from 'react';
import {usePagination} from 'ahooks';
import axios from '../Utilities/axios';
import {Box, Grid, LinearProgress, Pagination,} from '@mui/material';
import {useEmployeeContext} from "../context/PuzzelContext";

export default function AgentResponses() {
    const {employeeState} = useEmployeeContext();
    const {email} = employeeState;

    const goToPage = async (e: any, value: number) => {
        pagination.changeCurrent(value);
    };

    // Loads all agent responses
    const getAgentResponses = (params: {
        current: number;
        pageSize: number;
    }): Promise<any> => {
        if (!email) return Promise.resolve(undefined);
        return axios.post(
            `/responses/employee?skip=${
                params.pageSize * (params.current - 1)
            }&take=${params.pageSize}`,
            {
                email: email,
            }
        )
    };

    const {data, loading, pagination} = usePagination(
        getAgentResponses,
        {
            cacheKey: `AgentResponses`,
            pollingInterval: 5 * 60 * 1000, // 5 minutes
            refreshDeps: [email],
        }
    );

    return (
        <>
            {data && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box m={2}/>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Pagination
                            count={Math.ceil(data.total / data.take)}
                            page={pagination.current}
                            onChange={goToPage}
                            showFirstButton
                            showLastButton
                        />
                    </Grid>
                    {loading && (
                        <Grid item xs={12}>
                            <LinearProgress/>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LatestResponses
                            loadingAnimations={false}
                            showRespondentPhone={true}
                            showPgapRating={true}
                            responses={data.results}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}
