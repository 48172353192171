import { Avatar, Theme, useTheme } from '@mui/material';
import React from 'react';

function getColor(rating: number, theme: Theme) {
	if (rating >= 9) {
		return theme.palette.success.main;
	} else if (rating < 7) {
		return theme.palette.error.main;
	} else {
		return theme.palette.warning.main;
	}
}

function getPGapColor(pGap: number, theme: Theme) {
	if (pGap <= 1) {
		//0-1 is good
		return theme.palette.success.main;
	} else if (pGap === 2) {
		//2 is ok
		return theme.palette.warning.main;
	} else {
		return theme.palette.error.main; //over 3 is bad
	}
}

export type RatingAvatarProps = {
	rating: number | null;
	pGap?: number;
};

export default function RatingAvatar({ rating, pGap }: RatingAvatarProps) {
	const theme = useTheme();
	if (rating === null) return null;

	if (pGap || pGap === 0) {
		return (
			<Avatar sx={{ backgroundColor: getPGapColor(pGap, theme) }}>
				{rating}
			</Avatar>
		);
	}
	return <Avatar sx={{ bgcolor: getColor(rating, theme) }}>{rating}</Avatar>;
}
