import {axiosNpsWorkersInstance} from "../Utilities/axios";
import {useEmployeeContext} from "../context/PuzzelContext";
import {Respondent} from "../models/nps";
import phone from "phone";

export function useApi() {
    const {
        setShouldHavePGap,
        employeeState,
        puzzelState,
        delayNewCall,
        delayEndCall,
        delayEndChat
    } = useEmployeeContext();
    const {group, userId, email} = employeeState
    const {queueKey, object, formattedNumber, agentRating, agentAnswers, sessionId} = puzzelState

    function addNewCall(respondent: Respondent, timestamp: number): Promise<any> {
        let url = `puzzel/add/${sessionId}?queueKey=${queueKey}&timestamp=${timestamp}&status=New&userId=${userId}`
        //outcomment if we want to add delay to new call
        /*if (delayNewCall) {
            url += `&offset=${delayNewCall}`
        }*/
        return axiosNpsWorkersInstance.post(url, respondent);
    }

    function addNewChat(respondent: Respondent): Promise<any> {
        let url = `puzzel/chat/${sessionId}?queueKey=${queueKey}`
        if (delayEndChat) {
            url += `&offset=${delayEndChat}`
        }
        return axiosNpsWorkersInstance.post(url, respondent);
    }

    function putEndCall(respondent: Respondent, timestamp: number): Promise<any> {
        let url = `puzzel/add/${sessionId}?queueKey=${queueKey}&timestamp=${timestamp}&status=Ended&userId=${userId}`
        //outcomment if we want to add delay to end call
        /*if (delayEndCall) {
            url += `&offset=${delayEndCall}`
        }*/
        return axiosNpsWorkersInstance.post(url, respondent);
    }

    function convertObjectToRespondent(): Respondent | null {
        if (!object || !formattedNumber || !email || !group || !userId) return null;
        if (("caller" in object)) {
            return {
                respondent: {
                    phoneNumber: formattedNumber,
                },
                employee: {
                    email: email,
                    firstName: object.vars["system_last_user_firstname"],
                    lastName: object.vars["system_last_user_lastname"],
                    team: group,
                    externalId: userId
                },
                custom: {
                    sessionId: object.sessionId,
                    queue: object.queueKey,
                    queueDuration: object.vars["system_queue_time"],
                    callDuration: Math.round(object.vars['system_speaktime'] / 1000),
                    rawNumber: object.caller,
                    callProgress: object.vars["system_call_progress"],
                    callState: object.vars["current_state"],
                    agentEvent: object.vars['agentEvent.cause'],
                    crmdbLastcallStart: object.vars["crmdb_lastcall_start"],
                },
                agentAnswers: agentAnswers,
                agentRating: agentRating
            }
        } else {
            
            const phoneNumber = object.variables.find(v => v.name === "phoneNumber" || v.source === "CHATAPI")?.value;
            let customerNumber: string | null;

            if (!phone(phoneNumber, {country: 'DK'}).isValid) {
                if (!phone(phoneNumber.replace(/0{2}/, '+')).isValid) {
                    customerNumber = phoneNumber; // Call is not danish/ is hidden
                } else {
                    customerNumber = phone(phoneNumber.replace(/0{2}/, '+')).phoneNumber;
                }
            } else {
                customerNumber = phone(phoneNumber, {country: 'DK'}).phoneNumber;
            }

            return {
                respondent: {
                    phoneNumber: customerNumber ?? formattedNumber,
                },
                employee: {
                    email: email,
                    team: group,
                    externalId: userId
                },
                custom: {
                    sessionId: object.sessionId,
                    queue: object.queueKey,
                    chatState: object.status,
                },
                agentAnswers: agentAnswers,
                agentRating: agentRating
            };
        }
    }

    function newCall() {
        // call api new call
        const payload = convertObjectToRespondent()
        if (!payload) return;
        if (object && "caller" in object) {
            addNewCall(payload, object.timestamp).then(r => {
                console.log(r); //TODO: is logic missing here?
            })
        }
    }

    function endCall() {
        // call api end call
        const payload = convertObjectToRespondent()
        if (!payload) return;
        if (object && "caller" in object) {
            putEndCall(payload, object.timestamp).then(r => {
                setShouldHavePGap(r.pgap);
            });
        }
    }

    function endChat() {
        const payload = convertObjectToRespondent()
        if (!payload) return;
        addNewChat(payload).then(r => {
            setShouldHavePGap(r.pgap);
        });
    }


    return {newCall, endCall, endChat};
}