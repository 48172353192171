import { createTheme } from '@mui/material';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#000300',
		},
		secondary: {
			main: '#eae3d5',
		},
		error: {
			main: '#c64d4a',
		},
		success: {
			main: '#88bca5',
		},
		warning: {
			main: '#f5d45f',
		},
		info: {
			main: '#0099E0',
		},
	},
	typography: {
		fontFamily: `"WorkSansRegular", sans-serif`,
	},
});
