import LatestResponses from './LatestResponses';
import {Box, Grid} from '@mui/material';
import React, {useEffect} from 'react';
import {useRequest} from 'ahooks';
import LinearProgress from '@mui/material/LinearProgress';
import Profile from './Profile';
import axios from '../Utilities/axios';
import {useEmployeeContext} from "../context/PuzzelContext";

export default function PhoneResponses() {
    function getPhoneResponses(data): Promise<any> {
        return axios.post('/responses/phone', {...data})
    }

    const {puzzelState} = useEmployeeContext();
    const {formattedNumber} = puzzelState;

    const {data, loading, run} = useRequest(
        () => {
            return getPhoneResponses({phone: formattedNumber})
        },
        {
            cacheKey: 'PhoneResponses',
            pollingInterval: 5 * 60 * 1000,
            manual: true,
        }
    );

    useEffect(() => {
        if (!formattedNumber) return;
        run();
    }, [formattedNumber, run]);

    // initial loading
    if (loading && !data) {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <LinearProgress color="secondary"/>
            </Grid>
        );
    }

    return <>
        {data && (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Box m={2}/>
                {loading && <LinearProgress color="secondary"/>}
                <Profile/>
                <Grid item xs={12}>
                    <LatestResponses
                        loadingAnimations={false}
                        showRespondentPhone={true}
                        showPgapRating={true}
                        responses={data}
                    />
                </Grid>
            </Grid>
        )}
    </>;
}
