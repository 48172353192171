import * as React from 'react';
import {AccountCircleRounded, Phone, Chat} from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import {useEmployeeContext} from "../context/PuzzelContext";

export type NavigationTabsProps = {
    value: number;
    setValue: (value: number) => void;
};

export default function NavigationTabs({
                                           setValue,
                                           value,
                                       }: NavigationTabsProps) {
    const {puzzelState} = useEmployeeContext();
    const {formattedNumber, type} = puzzelState;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const height = '40px';

    return (
        <Paper sx={{width: 'min-content'}}>
            <Tabs
                sx={{height: height, minHeight: height}}
                textColor="primary"
                indicatorColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="navigation tabs"
            >
                <Tab
                    sx={{height: height, minHeight: height}}
                    icon={<AccountCircleRounded/>}
                    iconPosition="start"
                    label="Agent"
                />
                {type && <Tab
                    sx={{height: height, minHeight: height}}
                    icon={type === "call" ? <Phone/> : <Chat/>}
                    iconPosition="start"
                    label={formattedNumber ? formattedNumber : 'Phone'}
                />}
            </Tabs>
        </Paper>
    );
}
