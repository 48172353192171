import React from 'react';
import ResponseDetails from './ResponseDetails';
import { List, Snackbar } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert } from '@mui/material';
import { Response } from '../models/nps';

export type LatestResponsesProps = {
	responses: Response[];
	loadingAnimations: boolean;
	number?: number;
	showPgapRating: boolean;
	showRespondentPhone: boolean;
};

function LatestResponses({
	loadingAnimations,
	number,
	responses,
	showPgapRating,
	showRespondentPhone,
}: LatestResponsesProps) {
	return (
		<List id="comments">
			{responses?.length > 0 ? (
				responses.map((response) => {
					return (
						<ResponseDetails
							response={response}
							showPgapRating={showPgapRating}
							showRespondentPhone={showRespondentPhone}
							key={response.id}
						/>
					);
				})
			) : loadingAnimations ? (
				<LinearProgress color="secondary" />
			) : number ? (
				<Snackbar open={true}>
					<Alert severity="info">
						phone number has not answered surveys yet
					</Alert>
				</Snackbar>
			) : (
				<Snackbar open={true}>
					<Alert severity="info">Waiting for new caller...</Alert>
				</Snackbar>
			)}
		</List>
	);
}

export default LatestResponses;
