import {
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import {Phone} from '@mui/icons-material';
import {useEmployeeContext} from "../context/PuzzelContext";

function Profile() {
    const {puzzelState} = useEmployeeContext();
    const {formattedNumber} = puzzelState;
    return (
        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <Phone/>
            <Tooltip title="Phone number">
                <Typography>{formattedNumber}</Typography>
            </Tooltip>
            {/*<div>*/}
            {/*	<List dense={true}>*/}
            {/*		{props.number && (*/}
            {/*			<ListItem>*/}
            {/*				<ListItemIcon>*/}
            {/*					<Tooltip title="Phone number">*/}
            {/*						<Phone />*/}
            {/*					</Tooltip>*/}
            {/*				</ListItemIcon>*/}
            {/*				<ListItemText primary={props.number} />*/}
            {/*			</ListItem>*/}
            {/*		)}*/}
            {/*	</List>*/}
            {/*</div>*/}
        </Grid>
    );
}

export default Profile;
