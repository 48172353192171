import {
	Card,
	CircularProgress,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import GaugeChart from 'react-gauge-chart';
import { AgentAnalytics } from './AgentOverview';

const getPercentage = (score: number) => {
	const graphMax = 200; //range -100 to 100
	const calculatedScore = score + 100; //calculateNpsScore(props)+100;//add 100 to score since total is 200 and not 100
	return calculatedScore / graphMax;
};

const getScore = (data: AgentAnalytics) => {
	const count = data.ratingsCount;
	const total = data.count;
	let countDetractors = 0;
	let countPromoters = 0;

	countDetractors += count?.zero;
	countDetractors += count?.one;
	countDetractors += count?.two;
	countDetractors += count?.three;
	countDetractors += count?.four;
	countDetractors += count?.five;
	countDetractors += count?.six;

	countPromoters += count?.nine;
	countPromoters += count?.ten;

	return (countPromoters / total - countDetractors / total) * 100;
};

type NPSGaugeProps = {
	nps: AgentAnalytics;
	isLoading: boolean;
};

const Chart = React.memo(({ percentage }: any) => {
	const theme = useTheme();
	return (
		<GaugeChart
			id="NpsGaugheId"
			animDelay={0}
			hideText={true}
			nrOfLevels={10}
			colors={[theme.palette.grey['100'], theme.palette.grey['900']]}
			arcWidth={0.1}
			arcPadding={0.03}
			// colors={[
			// 	theme.palette.error.main,
			// 	theme.palette.warning.main,
			// 	theme.palette.success.main,
			// ]}
			percent={percentage}
			// arcPadding={0.02}
		/>
	);
});

export default function NPSGauge({ nps, isLoading }: NPSGaugeProps) {
	let score = Math.round(getScore(nps));
	if (isNaN(score)) {
		score = 0;
	}
	const percentage = getPercentage(score);

	return (
		<>
			{nps !== undefined && (
				<StyledCard>
					<Stack
						direction="column"
						alignItems="center"
						justifyContent="center"
						sx={{ width: 1, py: 2 }}
					>
						<Chart percentage={percentage} />
						<Typography variant="h4" sx={{ mb: 0.5 }}>
							{isLoading ? <CircularProgress /> : score}
						</Typography>
						<Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
							NPS Score
						</Typography>
					</Stack>
				</StyledCard>
			)}
		</>
	);
}

const StyledCard = styled(Card)(() => ({
	height: '100%',
	display: 'flex',
}));
