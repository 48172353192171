import './App.css';
import Call from "./Components/Call";
import PGapDialog from "./Components/Pgap";
import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, setupAppInsights } from './Utilities/appInsights';

setupAppInsights();

function App() {
    return (
        <div>
            <AppInsightsContext.Provider value={reactPlugin}>
                <Call/>
                <PGapDialog/>
            </AppInsightsContext.Provider>
        </div>
    )
}

export default withAITracking(reactPlugin, App);