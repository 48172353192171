import {useEmployeeContext} from "../context/PuzzelContext";
import Ratings from "./Ratings";
import {useEffect} from "react";
import {useApi} from "../hooks/api";
import {usePrevious} from "ahooks";
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';



export default function Call() {
    const appInsights = useAppInsightsContext();
    useEffect(() => {
        console.log('App component mounted');
        // Track a page view when the component mounts
        appInsights.trackPageView({ name: 'App' });

        // Example of tracking a custom event
        appInsights.trackEvent({ name: 'App Loaded' });

        appInsights.trackMetric({ name: 'App Loaded', average: 42 }, { name: 'App Loaded', min: 0, max: 100 });
    }, []);

    const {puzzelState, widgetControls} = useEmployeeContext();
    const {state, type, agentAnswers, agentRating} = puzzelState;
    const {newCall, endCall, endChat} = useApi();

    const previousState = usePrevious(state);

    useEffect(() => {
        if (previousState === state || !state || !type) return;
        switch (state) {
            case "incoming":
                if (type === "call")
                    newCall();
                break;
            case "ended":
                if (type === "call")
                    endCall()
                if (type === "chat")
                    endChat()
                break;
        }
    }, [state, type, agentAnswers, agentRating, newCall, endCall, endChat, previousState]);

    return (
        <>
        {!widgetControls.hideWidget && <Ratings/>}
        </>
    )
}